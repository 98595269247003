import React from 'react'
import { useParams, useHistory } from 'react-router-dom'

export default function Game()
{
    const { id } = useParams();
    const history = useHistory()
    console.log(id);

    if (!id)
    {
        console.log()
        history.replace("/games");
        return <></>
    }



    return (
        <div>
            <label>{id}</label>
        </div>
    )
}
