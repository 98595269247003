import React from 'react'

import './gameRow.css';

export default function GameRow({ game, handleEditGame, handleDeleteGame, handlePublishGame })
{
    return (
        <>
            {game &&
                <div className="my-game">
                    <div className="my-game-row">
                        {game.image &&
                            <img className="my-game-image" src={game.image.url} alt={game.image.fileName} />
                        }
                        <label className="my-game-title">{game.name}</label>
                        <label className="my-game-upload-details">{game.description}</label>
                        <div className="my-game-interactions">
                            <button onClick={() => handleEditGame && handleEditGame(game)} className="my-game-button">Edit</button>
                            <button onClick={() => handleDeleteGame && handleDeleteGame(game)} className="my-game-button red">Delete</button>
                        </div>
                    </div>
                    <div className="my-game-details">
                        <div className="my-game-details-section">
                            <label className={(game.isPublished ? "published" : "unpublished")}>{(!game.isPublished ? "Unpublished" : "Published")}</label>
                        </div>
                        <div className="my-game-details-section">
                            <label>Category:</label>
                            <label>{game.category.name}</label>
                        </div>
                        <div className="my-game-details-section">
                            <label>Downloads:</label>
                            <label>{game.downloads && game.downloads || 0}</label>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
