import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { getFirestore, doc, getDoc, runTransaction, getDocs, collection } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import { ref, getStorage, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import Compressor from 'compressorjs';
import Gallery from '../../partials/Gallery';
import './createGame.css';


const categories = [
    { name: 'Action/Adventure', id: 1 },
    { name: 'RPG', id: 2 },
    { name: 'Top-Down', id: 3 },
    { name: 'Text-Based', id: 4 },
    { name: 'Open World', id: 5 },
    { name: 'Side-Scroller', id: 6 },
    { name: 'Shooter', id: 7 },
    { name: 'Sports', id: 8 },
    { name: 'Other', id: 9 },
];


export default function CreateGame()
{
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState(null);
    const [files, setFiles] = useState([]);
    const imagePickerRef = useRef();
    const [isMultiplayer, setIsMultiplayer] = useState(false);
    const [gameID, setGameID] = useState(null);
    const [isPublished, setIsPublished] = useState(false);
    const [imagesToDelete, setImagesToDelete] = useState([]);
    const history = useHistory();
    const db = getFirestore();
    const storage = getStorage();
    const auth = getAuth();

    const { id } = useParams();


    useEffect(() =>
    {
        if (id)
        {
            setGameID(id);
            try
            {
                (async () =>
                {
                    const gameSnapshot = await getDoc(doc(db, "games", id));
                    const imagesSnapshot = await getDocs(collection(db, "games", id, "images"));

                    if (gameSnapshot.exists())
                    {
                        const game = gameSnapshot.data();
                        if (game.userID !== auth.currentUser.uid)
                        {
                            history.replace("/games/create");
                        }
                        const isPublished = !game.isPublished ? false : true;
                        setName(game.name + (isPublished ? " - Published" : ""));
                        setFiles(imagesSnapshot.docs.map((imageDoc) => 
                        {
                            const imageData = imageDoc.data();
                            return { id: imageDoc.id, fileName: imageData.fileName, url: imageData.url, extension: imageData.extension };
                        }));
                        setDescription(game.description);
                        setCategory(game.category.id);
                        setIsMultiplayer(game.isMultiplayer);
                        setIsPublished(isPublished);
                    }
                    else
                    {
                        history.replace("/games/create");
                        return;
                    }
                })();
            }
            catch (ex)
            {
                console.log(ex);
                return;
            }
        }
        else
        {
            setGameID(uuidv4());
        }
    }, []);


    function compressImages(images)
    {
        try
        {
            let result = [];
            for (let i = 0; i < images.length; i++)
            {
                const imageObject = {
                    id: uuidv4(),
                    fileName: images[i].name,
                    getBlob: async () => await new Promise((resolve) =>
                    {
                        new Compressor(images[i], {
                            quality: .9,
                            maxWidth: 1024,
                            maxHeight: 1024,
                            success: (newFile) => resolve(newFile)
                        });
                    })
                };
                result.push(imageObject);

            }

            setFiles(files.concat(result));
        }
        catch (ex) 
        {
            console.log(ex);
        }
    }

    function removeWhitespaces(value)
    {
        if (!isNullOrEmpty(value))
        {
            return value.replace(/\r?\n|\r/g, "").trim();
        }

        return '';
    }

    function stripHtml(value)
    {
        if (!isNullOrEmpty(value))
        {
            return value.replace(/&[^(\s|;)]*/g, "&amp;").replace(/<[^>]*>/g, "");
        }

        return '';
    }

    function isNullOrEmpty(value)
    {
        if (!value || value.trim() === '')
        {
            return true;
        }

        return false
    }

    async function saveGame()
    {
        const selectedCategory = categories.filter(x => x.id == category)[0];

        if (isNullOrEmpty(name))
        {
            alert("Your game must have a name");
            return;
        } else if (isNullOrEmpty(description))
        {
            alert("Your game must have a description");
            return;
        } else if (!files || files.length === 0)
        {
            alert("Your game must have at least one image");
            return;
        } else if (!selectedCategory || selectedCategory.id <= 0)
        {
            alert("Your game must belong to a category");
            return;
        }

        setName(stripHtml(name));
        setName(removeWhitespaces(name));

        setDescription(stripHtml(description));


        // database transaction that only passes if the user has access to database and file storage
        const gameRef = doc(db, "games", gameID);
        try
        {
            await runTransaction(db, async (transaction) =>
            {
                transaction.set(gameRef, {
                    name: name,
                    description: description,
                    category: selectedCategory,
                    isMultiplayer: isMultiplayer,
                    userID: auth.currentUser.uid,
                    createdDate: new Date()
                }, { merge: true });

                const imagesToAdd = [];

                try
                {
                    for (let file of imagesToDelete)
                    {
                        await deleteObject(ref(storage, "images/" + file.id + "." + file.extension));
                        transaction.delete(doc(db, "games", gameID, "images", file.id));
                    }

                    for (let i = 0; i < files.length; i++)
                    {
                        if (typeof files[i].getBlob === "function")
                        {
                            const fileNamePieces = files[i].fileName.split('.');
                            const extension = fileNamePieces[fileNamePieces.length - 1];
                            const imageRef = ref(storage, 'images/' + files[i].id + '.' + extension);
                            const uploadTask = await uploadBytes(imageRef, await files[i].getBlob());
                            const url = await getDownloadURL(uploadTask.ref);
                            const savedImageRef = doc(db, "games", gameID, "images", files[i].id);

                            imagesToAdd.push({
                                ref: savedImageRef,
                                image: { fileName: files[i].fileName, extension: extension, url: url }
                            });
                        }
                    }
                }
                catch (ex)
                {
                    console.log(ex);
                    let errorMessage = "An unknown error occurred while uploading your images. Please try again";
                    if (ex.code)
                    {
                        switch (ex.code)
                        {
                            case "storage/unauthenticated":
                                errorMessage = "You are not currently signed in. Please sign in and try again.";
                                break;
                            case "storage/unauthorized":
                                errorMessage = "One or more of your files is either in the incorrect format or exceeds 2MB.";
                                break;
                            case "storage/server-file-wrong-size":
                                errorMessage = "One or more of your files' size does not match what was recieved by the server. Please try again";
                                break;
                            default:
                                break;
                        }
                    }

                    return Promise.reject(errorMessage);
                }

                for (let image of imagesToAdd)
                {
                    transaction.set(image.ref, image.image);
                }
            });
        }
        catch (ex)
        {
            alert(ex);
            return;
        }

        history.push("/games/create/" + gameID);
    }

    function cancelChanges()
    {
        if (window.confirm("Are you sure you would like to cancel?"))
        {
            history.replace("/games");
        }
    }

    function deleteImage(image)
    {
        setFiles(files.filter(file => file.id !== image.id));
        if (!image.getBlob)
        {
            setImagesToDelete([...imagesToDelete, image]);
        }
    }

    return (
        <div className="create-game-wrapper">
            <div className="create-game-form">
                <div className="create-game-header">
                    <input type="text" disabled={isPublished} value={name} onChange={(e) => setName(e.target.value)} className="create-game-input" placeholder="Title of your Game" />
                    <div className="header-buttons-wrapper">
                        <button disabled={isPublished} onClick={cancelChanges} className="create-game-header-button">Cancel</button>
                        <button disabled={isPublished} onClick={saveGame} className="create-game-header-button blue">Save</button>
                    </div>
                </div>
                <div className="create-game-gallery">
                    <Gallery images={files} handleImageDeleted={deleteImage} />
                </div>
                <div className="create-game-file-picker">
                    <input ref={imagePickerRef} type="file" multiple accept="image/jpeg, image/png" name="imagePicker" onChange={(e) => compressImages(e.target.files)} />
                </div>
                <button disabled={isPublished} className="create-game-image-picker-button" onClick={() => imagePickerRef.current?.click()}>Choose Images</button>
                <div className="create-game-description-wrapper">
                    <label>Write a detailed description about your game</label>
                    <textarea value={description} disabled={isPublished} onChange={(e) => setDescription(e.target.value)} className="create-game-description">Write a detailed game description here</textarea>
                </div>
                <select value={category} disabled={isPublished} onChange={(e) => setCategory(e.target.value)} className="create-game-picker">
                    <option>Select a Category</option>
                    {categories.map(category => <option value={category.id} key={category.id}>{category.name}</option>)}
                </select>
                <div className="create-game-checkbox-row">
                    <input disabled={isPublished} type="checkbox" checked={isMultiplayer} onChange={() => setIsMultiplayer(!isMultiplayer)} id="create-game-is-multiplayer" />
                    <label htmlFor="create-game-is-multiplayer">Is Multiplayer</label>
                </div>
                <h2>Uploading for a Contest?</h2>
            </div>
        </div>
    )
}
