import React, { useState, useEffect } from 'react'
import './header.css'
import logo from '../images/logo.png'
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Link } from 'react-router-dom';

export default function Header()
{

    const [username, setUsername] = useState(null);
    const auth = getAuth();


    onAuthStateChanged(auth, (user) =>
    {
        if (user)
        {
            setUsername(user.displayName);
        } else
        {
            setUsername(null);
        }
    });


    useEffect(() =>
    {

    }, []);


    return (
        <div className="header">
            <Link to="/">
                <div className="logo-wrapper">
                    <img src={logo} alt="Mioti Games" />
                </div>
            </Link>
            <h1>Mioti Games</h1>
            <div className="profile-container">
                {username &&
                    <Link to="/profile">
                        <label className="header-username">{username}</label>
                    </Link>
                }
                {username &&
                    <Link to="/games/create">
                        <button>Create</button>
                    </Link>
                }
                {!username &&
                    <Link className="loginButton" to="/login">Login</Link>
                }
            </div>
        </div>
    )
}