import React, { useState, useEffect } from 'react'
import './gallery.css'

export default function Gallery({ images, handleImageDeleted })
{

    const [currentImage, setCurrentImage] = useState(null);
    const [imagesToRender, setImagesToRender] = useState(images);

    useEffect(() =>
    {
        (async () =>
        {
            if (images && images.length > 0)
            {
                for (let i = 0; i < images.length; i++)
                {
                    images[i].url = await getUrl(images[i]);
                }
                setCurrentImage(images[0]);
                setImagesToRender(images);

            }
        })();
    }, [images]);


    function getImageSourceFromBlob(file)
    {
        return new Promise((resolve) =>
        {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(file);
        });
    }


    async function getUrl(image)
    {
        if (!image)
        {
            return '';
        }

        if (image.url !== undefined && image.url !== null)
        {
            return image.url;
        }

        if (image.getBlob !== undefined && image.getBlob !== null)
        {
            return await getImageSourceFromBlob(await image.getBlob())
        }
    }


    return (
        <div className="gallery">
            <div className="gallery-display-image">
                {currentImage &&
                    <img className="gallery-current-image" src={currentImage.url} alt={currentImage.name} />
                }
                {!currentImage &&
                    <label style={{ color: "white" }}>Upload photos here</label>
                }
            </div>
            <div className="gallery-images-wrapper">
                <div className="gallery-images">
                    {imagesToRender &&
                        imagesToRender.map(image =>
                            <div key={image.id} className="gallery-image-wrapper">
                                <img src={image.url} alt={image.id} className="gallery-thumbnail" onClick={() => setCurrentImage(image)} />
                                <label className="gallery-delete-image" onClick={() => handleImageDeleted(image)}>X</label>
                            </div>)
                    }
                </div>
            </div>
        </div>
    )
}
