import React, { useEffect } from 'react';
import './landing.css';

export default function Landing()
{

    useEffect(() => 
    {
        getGames();
    }, []);

    async function getGames()
    {

    }

    return (
        <div>
            <label></label>
        </div>
    )
}
