import React, { useEffect } from 'react'
import { getAuth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { useHistory } from 'react-router-dom'

const provider = new GoogleAuthProvider();

export default function Login()
{

    const auth = getAuth();
    const db = getFirestore();
    const history = useHistory();

    useEffect(() =>
    {
        onAuthStateChanged(auth, async (user) =>
        {
            if (user)
            {
                await setDoc(doc(db, "users", user.uid), {
                    email: user.email,
                    name: user.displayName,
                    profilePicture: user.photoURL
                }, { merge: true });

                history.replace("/");
            }
        });
    }, [])


    function loginWithGoogle()
    {
        signInWithPopup(auth, provider).then((result) =>
        {

        }).catch((error) =>
        {

        })
    }

    return (
        <div id="login">
            <div className="login-wrapper">
                <div id="login-container" onClick={loginWithGoogle}>Login with Google</div>
            </div>
        </div>
    )
}
