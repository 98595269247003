import { useEffect } from 'react';
import Header from './partials/Header';
import './App.css';
import Landing from './components/landing/Landing';
import Login from './components/login/Login';
import { initializeApp } from 'firebase/app';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import GamesRouter from './components/games/GamesRouter';
import Game from './components/games/Game';
import Profile from './components/profile/Profile';

function App()
{

    const firebaseConfig = {
        apiKey: "AIzaSyC_QHLwBzafIWDZrceCvAFd0_rPc0xrmAY",
        authDomain: "mioti-games.firebaseapp.com",
        projectId: "mioti-games",
        storageBucket: "mioti-games.appspot.com",
        messagingSenderId: "724338250358",
        appId: "1:724338250358:web:4f7b9d10407d11a309c557",
        measurementId: "G-E9LPKC8L4H",
    };

    initializeApp(firebaseConfig);

    useEffect(() =>
    {

    }, []);

    return (
        <Router>
            <Header />
            <main>
                <Switch>
                    <Route exact path="/">
                        <Landing />
                    </Route>
                    <Route path="/login">
                        <Login />
                    </Route>
                    <Route path="/games">
                        <GamesRouter />
                    </Route>
                    <Route exact path="/game">
                        <Redirect to={{ pathname: "/games" }} />
                    </Route>
                    <Route path="/game/:id">
                        <Game />
                    </Route>
                    <Route exact path="/profile">
                        <Profile />
                    </Route>
                    <Route path="/profile/:id">
                        <Profile />
                    </Route>
                </Switch>
            </main>
        </Router>
    );
}

export default App;
