import React from 'react'
import { Route, useRouteMatch, useLocation } from 'react-router-dom'
import CreateGame from './CreateGame';
import Games from './Games';



function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function GamesRouter() {

    const { path } = useRouteMatch();
    const query = useQuery()

    return (
        <>
            <Route exact path={path}>
                <Games query={query.get("query")} />
            </Route>
            <Route exact path={`${path}/create`}>
                <CreateGame />
            </Route>
            <Route path={`${path}/create/:id`}>
                <CreateGame />
            </Route>
        </>
    )
}
