import React, { useState, useEffect } from 'react'
import { onAuthStateChanged, getAuth } from 'firebase/auth'
import { doc, getDoc, getFirestore, collection, getDocs, query, where, limit, runTransaction, deleteDoc } from 'firebase/firestore'
import { useParams, useHistory } from 'react-router-dom';
import { ref, deleteObject, getStorage } from 'firebase/storage';

import './profile.css';
import GameRow from './GameRow';

export default function Profile()
{
    const db = getFirestore();
    const auth = getAuth();
    const history = useHistory();
    const { id } = useParams();
    const gamesRef = collection(db, "games");
    const storage = getStorage();

    const [user, setUser] = useState(null);
    const [games, setGames] = useState([]);
    const [myProfile, setMyProfile] = useState(false);
    const [gamesLibrary, setGamesLibrary] = useState([]);


    useEffect(() =>
    {
        if (!id)
        {
            // here for dev purposes, data exponentially grows on saving
            if (!user)
            {
                onAuthStateChanged(auth, async (authUser) =>
                {
                    if (authUser != null)
                    {
                        const dbUser = await getDoc(doc(db, "users", authUser.uid));
                        if (dbUser.exists())
                        {
                            setUser({ id: dbUser.id, ...dbUser.data() });
                            setMyProfile(true);
                        }
                    }
                });
            }
        }
        else
        {
            (async () =>
            {
                const userRef = doc(db, "users", id);
                const dbUser = await getDoc(userRef);

                if (dbUser.exists())
                {
                    setUser({ id: dbUser.id, ...dbUser.data() });
                    setMyProfile(false);
                }
                else
                {
                    history.replace("/profile");
                }
            })();
        }
    }, [auth, db, history, id, user]);


    useEffect(() =>
    {
        if (user != null)
        {
            (async () =>
            {
                let q = query(gamesRef, where("userID", "==", user.id));
                let result = await getDocs(q);
                let games = [];

                if (!result.empty)
                {
                    games = result.docs.map(game => ({ id: game.id, ...game.data() }));
                }

                for (let game of games) 
                {
                    const imagesRef = collection(db, gamesRef.path, game.id, "images");
                    q = query(imagesRef, limit(1));
                    result = await getDocs(q);

                    if (!result.empty)
                    {
                        game.image = result.docs.map(image => ({ id: image.id, ...image.data() }))[0];
                    }
                }

                setGames(games);
            })();
        }
    }, [user]);


    useEffect(() =>
    {
        if (myProfile)
        {
            (async () =>
            {
                const libraryRef = collection(db, "users", user.id, "gameLibrary");
                const games = await getDocs(libraryRef);

                if (!games.empty)
                {
                    setGamesLibrary(games.docs.map(game => ({ id: game.id, ...game.data() })));
                }
            })();
        }
    }, [myProfile]);


    useEffect(() =>
    {
        console.log(games);
    }, [games]);


    useEffect(() =>
    {
        console.log(gamesLibrary);
    }, [gamesLibrary]);


    function editGame(game)
    {
        if (game !== undefined && game !== null)
        {
            history.push("/games/create/" + game.id);
        }
    }

    async function deleteGame(game)
    {
        if (game !== undefined && game !== null && !game.isPublished)
        {
            if (window.confirm("Are you sure you would like to delete " + game.name + "? All content for this game will be removed permanently."))
            {
                try
                {
                    await runTransaction(db, async (transaction) =>
                    {
                        try
                        {
                            const gameRef = doc(db, "games", game.id);
                            const imagesRef = collection(db, gameRef.path, "images");
                            const imagesToDelete = [];

                            const imagesSnapshot = await getDocs(imagesRef);
                            imagesSnapshot.forEach(image => imagesToDelete.push(image.id + "." + image.data().extension));

                            transaction.delete(gameRef);

                            for (let image of imagesToDelete)
                            {
                                const imageRef = ref(storage, "images/" + image);
                                await deleteObject(imageRef);
                            }
                        }
                        catch (ex)
                        {
                            Promise.reject(ex);
                        }
                    });

                    setGames(games.filter(g => g.id !== game.id));

                }
                catch (ex)
                {
                    console.log(ex);
                }
            }
        }
    }


    function logout()
    {
        auth.signOut();
        history.push("/login");
    }


    return (
        <div id="user-profile">
            {user &&
                <div className="profile-details-wrapper">
                    <img src={user.profilePicture} alt={user.name} className="profile-picture" />
                    <div className="profile-image-wrapper">
                        <label className="profile-user-name">{user.name}</label>
                        {myProfile &&
                            <a className="profile-logout" onClick={logout}>logout</a>
                        }
                    </div>
                </div>
            }
            {(user && myProfile) &&
                <>
                    <h2>My Games</h2>
                    <div className="profile-my-games-wrapper">
                        <div className="profile-my-games-list">
                            {games &&
                                games.map(game => <GameRow key={game.id} game={game} handleEditGame={editGame} handleDeleteGame={deleteGame} />)
                            }
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
