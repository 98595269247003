import React from 'react'
//import { useParams } from 'react-router-dom'

export default function Games({ query })
{
    //const { id } = useParams();



    return (
        <div>

        </div>
    )
}
